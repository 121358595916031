import { Contract } from '@ethersproject/contracts';
import { Plane, Text, useAspect } from '@react-three/drei';
import { Canvas, ThreeElements, useFrame } from '@react-three/fiber';
import {
  Bloom,
  ChromaticAberration,
  DepthOfField,
  DotScreen,
  EffectComposer,
  Glitch,
  Grid,
  Noise,
} from '@react-three/postprocessing';
import { useContractFunction, useEtherBalance, useEthers } from '@usedapp/core';
import { utils } from 'ethers';
import IcecastMetadataPlayer, { IcyMetadata } from 'icecast-metadata-player';
import { BlendFunction, GlitchMode } from 'postprocessing';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as THREE from 'three';
import Connect from '../components/Connect';
import Modal from '../components/Modal';

type Block = {
  number: number;
  difficulty: number;
  gasLimit: number;
  gasUsed: number;
  timestamp: number;
  miner: string;
  parentHash: string;
  txRoot: string;
  msgs: string[];
};

const Box = (props: ThreeElements['mesh'] & { color: string }) => {
  const ref = useRef<THREE.Mesh>(null!);
  const [hovered, hover] = useState(false);
  useFrame((state, delta) => {
    //if (ref.current.rotation.x !== 5) ref.current.rotation.x = 5;
    if (ref.current.rotation.y !== 0) ref.current.rotation.y = 0;
    if (ref.current.rotation.z !== 0) ref.current.rotation.z = 0;
    ref.current.rotation.x -= 0.1 * delta;
  });

  useFrame((state, delta) => {
    if (ref.current) {
      ref.current.position.x -= 0.01 * delta;
      ref.current.position.y += 0.05 * delta;
      ref.current.position.z -= 0.2 * delta;
    }
  });
  return (
    <mesh
      {...props}
      ref={ref}
      onPointerOver={(event) => hover(true)}
      onPointerOut={(event) => hover(false)}
    >
      <boxGeometry args={[1, 1, 1]} />
      <meshStandardMaterial color={props.color} />
    </mesh>
  );
};

const Log = (props: any) => {
  const ref = useRef<THREE.Mesh>(null!);

  useFrame((state, delta) => {
    if (ref.current) {
      ref.current.position.y += 0.2 * delta;
    }
  });
  return (
    <Text
      {...props}
      ref={ref}
      color="white"
      castShadow={true}
      outlineColor={props.color}
      outlineBlur={props.color !== 'white' ? 0.2 : 0}
    >
      {props.value}
    </Text>
  );
};

const MergeText = (props: any) => {
  const ref = useRef<THREE.Mesh>(null!);

  useFrame((state, delta) => {
    if (ref.current) {
      // @ts-ignore
      ref.current.count = isNaN(ref.current.count) ? 0 : ref.current.count;
      ref.current.position.y += 0.35 * delta;
      // @ts-ignore
      ref.current.count += 2.4 * delta;
      // @ts-ignore
      ref.current.fillOpacity =
        // @ts-ignore
        1 * (parseInt(ref.current.count) % 2 === 0 ? 0 : 1);
      // @ts-ignore
      ref.current.outlineBlur =
        // @ts-ignore
        0.2 * (parseInt(ref.current.count) % 2 === 0 ? 0 : 1);
      // @ts-ignore
    }
  });

  return (
    <Text
      {...props}
      ref={ref}
      color="white"
      castShadow={true}
      outlineColor="purple"
      outlineBlur={0.2}
    >
      {props.value}
    </Text>
  );
};

const DifficultyText = (props: any) => {
  const ref = useRef<THREE.Mesh>(null!);

  return (
    <Text {...props} ref={ref} color="white">
      {props.value}
    </Text>
  );
};

const parseMetadata = (raw: string): Block => {
  const metadata = JSON.parse(raw) as {
    number: number;
    difficulty: number;
    gas_limit: number;
    gas_used: number;
    timestamp: number;
    miner: string;
    parent_hash: string;
    tx_root: string;
    msgs: string;
  };
  return {
    number: metadata['number'],
    difficulty: metadata['difficulty'],
    gasLimit: metadata['gas_limit'],
    gasUsed: metadata['gas_used'],
    timestamp: metadata['timestamp'],
    miner: metadata['miner'],
    parentHash: metadata['parent_hash'],
    txRoot: metadata['tx_root'],
    msgs: JSON.parse(metadata['msgs']),
  };
};

type Log = {
  // id must be unique per log, deterministic and never change after the log creation
  id: string;
  content: string;
  color?: string;
};

const craftLog = (log: string, blockNb: number, color?: string): Log => {
  return {
    // we use the block nb to make sure everything is repeatable & determinist
    id: `${log}-${blockNb}`,
    content: log,
    color,
  };
};

const Video = ({ src }: { src: string }) => {
  const scale = useAspect(1920, 1080, 3);
  // video texture by: https://www.pexels.com/@rostislav/
  const [video] = useState(() =>
    Object.assign(document.createElement('video'), {
      src,
      crossOrigin: 'Anonymous',
      loop: true,
      muted: true,
    })
  );
  useEffect(() => void video.play(), [video]);
  return (
    <>
      <mesh scale={scale} position={[0, 0, -10]}>
        <planeGeometry />

        <meshBasicMaterial toneMapped={true} side={THREE.DoubleSide}>
          <videoTexture
            attach="map"
            args={[video]}
            encoding={THREE.sRGBEncoding}
            format={THREE.RGBAFormat}
            premultiplyAlpha={true}
            // @ts-ignore
            transparent={true}
            // @ts-ignore
            opacity={0.5}
          />
        </meshBasicMaterial>
      </mesh>
      {/* Filter to atenuate the video colors */}
      <Plane args={[20, 10]} position={[0, 0, 0]}>
        <meshBasicMaterial color="gray" opacity={0.1} transparent />
      </Plane>
    </>
  );
};

const EthereumRadioViewer: React.FC<{
  blocks: { block: Block; color: string }[];
  effects: boolean;
  panda: boolean;
}> = ({ blocks, effects, panda }) => {
  const blockStart = [-1.5, 0, 2];
  const delta = 0.75;
  const [lastBlock, setLastBlock] = useState<undefined | Block>(
    blocks[0]?.block || undefined
  );
  const [logs, setLogs] = useState<Log[]>([
    {
      content: '[*] Starting Maethstro, waiting for new block...',
      id: 'init',
    },
  ]);
  const [dotOn, setDotOn] = useState<boolean>(false);
  const [gridOn, setGridOn] = useState<boolean>(false);
  const [pandaVideoOn, setPandaVideoOn] = useState<boolean>(false);
  const [extinction, setExtinction] = useState<boolean>(false);

  // remove old logs for performance
  useEffect(() => {
    if (logs.length > 50) {
      setLogs((l) => {
        l.pop();
        return l;
      });
    }
  }, [logs]);

  useEffect(() => {
    if (!panda && pandaVideoOn && !extinction) {
      setExtinction(true);
      setTimeout(() => {
        setPandaVideoOn(false);
        setExtinction(false);
      }, 15000);
    }
  }, [panda, pandaVideoOn, extinction]);

  useEffect(() => {
    if (panda && !pandaVideoOn) {
      setPandaVideoOn(true);
      console.log(`
    ,,,         ,,,
    ;"   ^;     ;'   ",
    ;    s$$$$$$$s     ;
    ,  ss$$$$$$$$$$s  ,'
    ;s$$$$$$$$$$$$$$$
    $$$$$$$$$$$$$$$$$$
   $$$$P""Y$$$Y""W$$$$$
   $$$$  p"$$$"q  $$$$$
   $$$$  .$$$$$.  $$$$
    $$DcaU$$$$$$$$$$
      "Y$$$"*"$$$Y"  
         "$b.$$"  

         MERGED
    `);
    }
  }, [panda]);

  // manage logs everytime a new block is added
  useEffect(() => {
    if (blocks[0]?.block !== lastBlock) {
      setLastBlock(blocks[0].block);
      const blockNb = blocks[0].block.number;
      setLogs((l) =>
        [
          craftLog(
            `*** New block ${
              blocks[0].block.difficulty === 0 ? 'validated' : 'mined'
            }: ${blockNb} ***`,
            blockNb,
            blocks[0].color
          ),
        ].concat(l)
      );
      const date = new Date(blocks[0].block.timestamp * 1000);
      setTimeout(() => {
        setLogs((l) =>
          [
            craftLog(
              `[*] Block ${
                blocks[0].block.difficulty === 0 ? 'validated' : 'mined'
              } at ${date.getMonth()}-${date.getDate()}-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
              blockNb
            ),
          ].concat(l)
        );
      }, 2000);
      setTimeout(() => {
        setLogs((l) =>
          [
            craftLog(
              `[*] Block filled at ${(
                (blocks[0]?.block.gasUsed * 100) /
                blocks[0]?.block.gasLimit
              ).toFixed(2)}%`,
              blockNb
            ),
          ].concat(l)
        );
      }, 3000);
      setTimeout(() => {
        setLogs((l) =>
          [
            craftLog(
              `[*] Block ${blocks[0].block.number} ${
                blocks[0].block.difficulty === 0 ? 'validated' : 'mined'
              } by ${blocks[0]?.block.miner}`,
              blockNb
            ),
          ].concat(l)
        );
      }, 4000);
      setTimeout(() => {
        for (let idx = 0; idx < blocks[0].block.msgs.length; ++idx) {
          const msg = blocks[0].block.msgs[idx];
          setTimeout(() => {
            setLogs((l) => [craftLog(`[*] ${msg}`, blockNb)].concat(l));
          }, 1000 * idx);
        }
      }, 5000);
    }
  }, [blocks]);

  // activate dot effet for 3 seconds, everytime 4 blocks in a determinist way
  useEffect(() => {
    if (lastBlock && lastBlock.number % 4 === 0) {
      setDotOn(true);
      setTimeout(() => {
        if (panda) {
          return;
        }
        setDotOn(false);
      }, 3000);
    }
  }, [lastBlock]);

  // activate grid effet for 5 seconds, everytime 6 blocks in a determinist way
  useEffect(() => {
    if (lastBlock && lastBlock.number % 6 === 0) {
      setGridOn(true);
      setTimeout(() => {
        if (panda) {
          return;
        }
        setGridOn(false);
      }, 5000);
    }
  }, [lastBlock]);

  return (
    <Canvas>
      <ambientLight />
      {/* change video every 3 blocks, in a determinist way */}
      {effects &&
        blocks.length > 0 &&
        blocks[0]?.block.number % 3 === 0 &&
        !pandaVideoOn && <Video src="/video.mp4" />}
      {effects &&
        blocks.length > 0 &&
        blocks[0]?.block.number % 3 !== 0 &&
        !pandaVideoOn && <Video src="/video1.mp4" />}
      {effects && blocks.length && pandaVideoOn ? (
        <Video src="/video-panda.mp4" />
      ) : (
        <></>
      )}
      <pointLight position={[10, 10, 10]} />
      {blocks.map(({ color, block }, i) => (
        <Box
          key={block.number}
          position={
            i === 0
              ? [
                  blockStart[0] - delta * i,
                  blockStart[1] + delta * i,
                  blockStart[2] - delta * i * 1.1,
                ]
              : undefined
          }
          color={color}
        />
      ))}
      {blocks.length && pandaVideoOn ? (
        <MergeText
          position={[-4, -5, 0]}
          scale={[10, 10, 10]}
          color="white"
          anchorX="left"
          anchorY="middle"
          value={'MERGED'}
        />
      ) : (
        <></>
      )}
      {logs.map(({ id, content, color }, i) => (
        <Log
          key={id}
          position={i === 0 ? [-0.5, -3, 0] : undefined}
          scale={[1.5, 1.5, 1.5]}
          color={color || 'white'} // default
          anchorX="left" // default
          anchorY="middle" // default
          value={content}
        />
      ))}
      {blocks.length && blocks[0].block.difficulty !== 0 && (
        <DifficultyText
          position={[-5.5, -3.5, 0]}
          scale={[1.5, 1.5, 1.5]}
          color={'white'} // default
          anchorX="left" // default
          anchorY="middle" // default
          value={`Difficulty: ${blocks[0].block.difficulty} | TTD: 58750000000000000000000`}
        />
      )}
      {effects && (
        <EffectComposer>
          {dotOn === true ? (
            <DotScreen
              blendFunction={BlendFunction.NORMAL} // blend mode
              angle={Math.PI * 0.5} // angle of the dot pattern
              scale={0.5} // scale of the dot pattern
            />
          ) : (
            <></>
          )}
          <DepthOfField
            focusDistance={0}
            focalLength={0.02}
            bokehScale={2}
            height={480}
          />
          <Bloom
            luminanceThreshold={0}
            luminanceSmoothing={0.9}
            height={1000}
          />
          <Noise opacity={pandaVideoOn ? 0.4 : 0.1} />

          {gridOn === true || pandaVideoOn ? (
            <Grid
              blendFunction={BlendFunction.OVERLAY} // blend mode
              scale={0.0} // grid pattern scale
              lineWidth={5} // grid pattern line width
              size={{ width: 100, height: 100 }} // overrides the default pass width and height
            />
          ) : (
            <></>
          )}
          {blocks[0]?.block.number % 2 !== 0 && !pandaVideoOn ? (
            <Glitch
              delay={new THREE.Vector2(7)} // min and max glitch delay
              duration={new THREE.Vector2(0.5, 0.5)} // min and max glitch duration
              strength={new THREE.Vector2(0.4, 0.4)} // min and max glitch strength
              mode={GlitchMode.SPORADIC} // glitch mode
              active // turn on/off the effect (switches between "mode" prop and GlitchMode.DISABLED)
              ratio={1} // Threshold for strong glitches, 0 - no weak glitches, 1 - no strong glitches.
            />
          ) : (
            <ChromaticAberration
              offset={
                new THREE.Vector2(pandaVideoOn ? 4 : 2, pandaVideoOn ? 4 : 2)
              }
            />
          )}
        </EffectComposer>
      )}
    </Canvas>
  );
};

const Tip = ({ onClose }: { onClose: () => void }) => {
  const [amount, setAmount] = useState<string>('0');
  const [message, setMessage] = useState<string>('');

  const wethInterface = new utils.Interface(
    '[{"inputs":[{"internalType":"string","name":"_message","type":"string"}],"name":"tip","outputs":[],"stateMutability":"payable","type":"function"}]'
  );
  const wethContractAddress = process.env.REACT_APP_ETH_TIP_ADDRESS as string;
  const contract = new Contract(wethContractAddress, wethInterface);
  const { state, send } = useContractFunction(contract, 'tip');
  const { account } = useEthers();
  const etherBalance = useEtherBalance(account);
  const ethBalance = parseInt(etherBalance?.toString() || '0') / 10 ** 18;

  const handleTip = () => {
    send(message, { value: utils.parseEther(amount) });
  };

  return (
    <Modal
      children={
        state.status === 'None' ? (
          <div className="flex flex-col w-[500px] p-10">
            <h1 className="font-bold text-2xl mx-auto mb-10">
              Send a message on the radio
            </h1>
            <label className="py-3">Amount (in ETH)</label>
            <input
              placeholder="Amount (in ETH)"
              type="number"
              className="p-3 rounded text-gray-500"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
            <p className="my-2 text-red-500">
              {etherBalance && ethBalance <= parseFloat(amount)
                ? 'Not enough funds'
                : ''}
            </p>
            <label className="py-3">Message</label>
            <input
              placeholder="Your message (max 64chars)"
              type="text"
              className="p-3 rounded text-gray-500"
              maxLength={64}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <button
              className={`py-3 px-5 rounded mt-10 hover:bg-purple-${
                etherBalance && ethBalance <= parseFloat(amount) ? '100' : '600'
              } bg-purple-${
                etherBalance && ethBalance <= parseFloat(amount) ? '100' : '500'
              }`}
              onClick={() => {
                if (
                  etherBalance && ethBalance > parseFloat(amount)
                    ? '100'
                    : '500'
                )
                  handleTip();
              }}
            >
              Send
            </button>
          </div>
        ) : state.status === 'PendingSignature' || state.status === 'Mining' ? (
          <div className="flex flex-col w-[500px] p-10">
            <p>Transaction pending...</p>
          </div>
        ) : state.status === 'Fail' || state.status === 'Exception' ? (
          <div className="flex flex-col w-[500px] p-10">
            <p>Transaction failed...</p>
          </div>
        ) : (
          <div className="flex flex-col w-[500px] p-10">
            <p>Success! Your message will appear soon on the radio!</p>
          </div>
        )
      }
      onClose={onClose}
    />
  );
};

const EthereumRadio = () => {
  const [player, setPlayer] = useState<IcecastMetadataPlayer>() as [
    IcecastMetadataPlayer,
    any
  ];
  const [metadata, setMetadata] = useState<string>();
  const [first, setFirst] = useState<boolean>(true);
  const [volume, setVolume] = useState<number>(100);
  const [data, setData] = useState<
    { price: number; marketcap: number } | undefined
  >(undefined);
  const [effects, setEffects] = useState<'on' | 'off'>('on');
  const { account } = useEthers();
  const [tip, showTip] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleLowerVolume = () => {
    if (volume > 10) {
      player.audioElement.volume -= 0.1;
      setVolume((v) => v - 10);
    }
  };

  const handleLouderVolume = () => {
    if (volume < 100) {
      player.audioElement.volume += 0.1;
      setVolume((v) => v + 10);
    }
  };

  const handleChangeEffects = () => {
    setEffects((e) => (e === 'on' ? 'off' : 'on'));
  };

  const handleHome = useCallback(
    () => navigate('/', { replace: true }),
    [navigate]
  );
  // fetch ethereum data at the componenent creation
  useEffect(() => {
    fetch(
      'https://api.coingecko.com/api/v3/coins/ethereum?localization=false&tickers=false&market_data=true&community_data=false&developer_data=false&sparkline=false'
    ).then(async (response) => {
      const res = (await response.json()) as any;
      setData({
        price: res['market_data']['current_price']['usd'],
        marketcap: res['market_data']['market_cap']['usd'],
      });
    });
  }, []);

  // initiate stream at the component creation
  useEffect(() => {
    setPlayer(
      new IcecastMetadataPlayer(process.env.REACT_APP_STREAM_URL as string, {
        onMetadata: (metadata: IcyMetadata) => {
          setMetadata(metadata.StreamTitle);
        },
        enableLogging: true,
      })
    );
  }, []);

  const [blocks, setBlocks] = useState<{ block: Block; color: string }[]>([]);
  const [panda, setPanda] = useState<boolean>(false);

  useEffect(() => {
    if (!metadata || metadata === 'maethstro') {
      return;
    }
    if (JSON.parse(metadata)['panda'] === true) {
      setPanda(true);
      return;
    }
    if (JSON.parse(metadata)['panda'] === false) {
      setPanda(false);
      return;
    }
    const block = parseMetadata(metadata);
    setBlocks((b) => {
      // remove last block when 5 are in the buffer for performance
      if (b.length >= 5) {
        b.pop();
      }
      if (first) {
        setFirst(false);
        return [];
      }
      const colors = ['#120078', '#9D0191', '#FD3A69', 'red'];
      // We use the block number to make sure the animation is determinist and replayable
      const idx = block.number % colors.length;
      return [
        {
          block,
          // make sure we don't have the same color 2 times in a row
          color: colors[idx],
        },
      ].concat(b);
    });
  }, [metadata]);

  const handleTip = () => {
    showTip(true);
  };

  const handleCloseTip = () => {
    showTip(false);
  };

  return (
    <>
      <div className="flex flex-col w-full h-full">
        {player &&
          player.state === 'playing' &&
          account !== undefined &&
          tip && <Tip onClose={handleCloseTip} />}

        {player && player.state === 'stopped' ? (
          <div className="flex flex-col w-full h-full">
            <pre
              className="text-center mt-10 hover:cursor-pointer"
              onClick={handleHome}
              style={{ float: 'left' }}
            >{`
  ███╗   ███╗ █████╗ ███████ ████████╗██╗  ██╗███████╗████████╗██████╗  ██████╗ 
  ████╗ ████║██╔══██╗╚════╝  ╚══██╔══╝██║  ██║██╔════╝╚══██╔══╝██╔══██╗██╔═████╗
  ██╔████╔██║███████║█████╗     ██║   ███████║███████╗   ██║   ██████╔╝██║██╔██║
  ██║╚██╔╝██║██╔══██║╚════╝     ██║   ██╔══██║╚════██║   ██║   ██╔══██╗████╔╝██║
  ██║ ╚═╝ ██║██║  ██║███████╗   ██║   ██║  ██║███████║   ██║   ██║  ██║╚██████╔╝
╚═╝     ╚═╝╚═╝  ╚═╝╚══════╝   ╚═╝   ╚═╝  ╚═╝╚══════╝   ╚═╝   ╚═╝  ╚═╝ ╚═════
              `}</pre>
            <div className="flex flex-col mt-10 text-lg max-w-[800px] mx-auto">
              <p className="mx-auto bold">// ETHEREUM radio //</p>
              <p className="mx-auto mt-10 text-gray-300 text-justify">
                MAΞTHSTR0 Ethereum radio is an autonomous machinery that
                produces music based on the Ethereum chain blocks.
              </p>
              <p className="mx-auto mt-10 text-gray-300 text-justify">
                This generative autonomous artist respects certain composition
                rules and will play more and more aggressively as we come close
                to The Merge. Expect something big when Ethereum consensus
                becomes Proof of Stake.
              </p>
              <p className="mx-auto mt-10 text-gray-300 text-justify">
                The Music and its animation are determinist and can be replayed.
                MAΞTHSTR0 may emit NFTs of special chain events.
              </p>
              <p className="mx-auto mt-10 text-gray-300 text-justify">
                You can connect your wallet and tip the radio to display a
                message in its logs that everyone can see. Only message with
                more than 0.005 ETH are displayed.
              </p>
              <p className="mx-auto mt-10 text-gray-300 text-justify">
                If you are subject to epilepsy, we recommend you deactivate the
                Effects, with the option available on the bottom left of the
                radio.
              </p>
              <button
                className="hover:cursor-pointer mx-auto ubold  mt-20 shadow-lg shadow-purple-800 mb-20"
                onClick={() => player.play()}
              >
                {'>>   Enter the Ethereum radio   <<'}
              </button>
            </div>
          </div>
        ) : (
          <></>
        )}

        {!player || player.state === 'loading' ? (
          <p className="mx-auto mt-[400px]">Loading...</p>
        ) : (
          <></>
        )}

        {player && player.state === 'playing' ? (
          <div className="flex flex-col w-[1200px] h-[1000px] mx-auto">
            <p className="my-10 font-bold text-center">
              MAΞTHSTR0 ΞTHΞRΞUM RADI0
            </p>
            <div className="flex justify-between mb-2">
              <Connect />
              <div className="flex jusitfy-between">
                <p>
                  Price: $
                  {data?.price
                    ? data.price
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    : '...'}
                </p>
                <p className=" ml-5">
                  Market Cap: $
                  {data?.marketcap
                    ? data.marketcap
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    : '...'}
                </p>
              </div>
            </div>
            <div className="w-full h-full border border-gray-400">
              <EthereumRadioViewer
                blocks={blocks}
                effects={effects === 'on'}
                panda={panda}
              />
            </div>
            <div className="mt-4 flex justify-between">
              <div className="flex">
                <button onClick={handleLowerVolume}>-</button>
                <p className="mx-4">Volume: {volume}</p>
                <button onClick={handleLouderVolume}>+</button>
                <button className="ml-14" onClick={handleChangeEffects}>
                  Effects: {effects}
                </button>
              </div>
              <div className="flex">
                {account !== undefined ? (
                  <p
                    className="text-purple-500 font-bold underline hover:cursor-pointer"
                    onClick={handleTip}
                  >
                    Display your message on the radio
                  </p>
                ) : (
                  <Connect />
                )}
              </div>

              <div className="flex">
                <a
                  className="mr-14"
                  target="_blank"
                  href="https://twitter.com/intent/tweet?text=Listening%20to%20the%20Ethereum%20chain%20on%20the%20%23maethstro%20radio.%20Join%20me%21%20https%3A%2F%2Fradio.maethstro.com%2Fethereum"
                >
                  <p>Tweet #maethstro</p>
                </a>
                <a target="_blank" href="https://maethstro.com/discord">
                  <p>Join the discord</p>
                </a>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default EthereumRadio;
