import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';

type Props = {
  children: any;
  onClose: () => void;
  noClose?: boolean;
};

const Modal: FC<Props> = ({ children, onClose, noClose }) => {
  return (
    <div
      className="absolute flex z-10 left-0 right-0 top-0 bottom-0 bg-gray-800 bg-opacity-60"
      onClick={onClose}
    >
      <div
        className="h-fit m-auto bg-[#121212] border border-[#989898] rounded w-fit p-5"
        onClick={(e) => e.stopPropagation()}
      >
        {!noClose && (
          <FontAwesomeIcon
            icon={faClose}
            style={{ fontSize: 15, color: 'white' }}
            className="hover:cursor-pointer"
            onClick={onClose}
          />
        )}
        {children}
      </div>
    </div>
  );
};

export default Modal;
