import { Config, DAppProvider, Goerli, Mainnet } from '@usedapp/core';
import { getDefaultProvider } from 'ethers';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './index.css';

// views
import Ethereum from './views/Ethereum';
import Home from './views/Home';
import NotFound from './views/NotFound';

const config: Config = {
  readOnlyChainId: Mainnet.chainId,
  readOnlyUrls: {
    [Mainnet.chainId]: getDefaultProvider('mainnet'),
    [Goerli.chainId]: getDefaultProvider('goerli'),
  },
};

const App = () => {
  return (
    <div className="min-w-screen w-full min-h-screen h-full bg-black text-gray-200">
      <React.StrictMode>
        <BrowserRouter>
          <DAppProvider config={config}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/ethereum" element={<Ethereum />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </DAppProvider>
        </BrowserRouter>
      </React.StrictMode>
    </div>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(<App />);
