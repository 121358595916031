const Home = () => {
  return (
    <>
      <script
        async
        defer
        src={process.env.REACT_APP_ANALYTICS_URL}
        data-website-id={process.env.}
      ></script>
      <div className="flex flex-col w-full h-full">
        <pre className="text-center mt-10" style={{ float: 'left' }}>{`
  ███╗   ███╗ █████╗ ███████ ████████╗██╗  ██╗███████╗████████╗██████╗  ██████╗ 
  ████╗ ████║██╔══██╗╚════╝  ╚══██╔══╝██║  ██║██╔════╝╚══██╔══╝██╔══██╗██╔═████╗
  ██╔████╔██║███████║█████╗     ██║   ███████║███████╗   ██║   ██████╔╝██║██╔██║
  ██║╚██╔╝██║██╔══██║╚════╝     ██║   ██╔══██║╚════██║   ██║   ██╔══██╗████╔╝██║
  ██║ ╚═╝ ██║██║  ██║███████╗   ██║   ██║  ██║███████║   ██║   ██║  ██║╚██████╔╝
╚═╝     ╚═╝╚═╝  ╚═╝╚══════╝   ╚═╝   ╚═╝  ╚═╝╚══════╝   ╚═╝   ╚═╝  ╚═╝ ╚═════
            `}</pre>
        <div className="flex justify-evenly text-md mx-auto text-gray-300 text-sm">
          <a href="https://maethstro.com">
            <p className="mx-auto">Website</p>
          </a>
          <a href="https://maethstro.com/twitter">
            <p className="mx-auto ml-32">Twitter</p>
          </a>
          <a href="https://maethstro.com/discord">
            <p className="mx-auto ml-32">Discord</p>
          </a>
        </div>
        <div className="flex flex-col mt-10 text-lg max-w-[800px] mx-auto">
          <p className="mx-auto bold">// Listen to MAΞTHSTR0 radios //</p>
          <p className="mx-auto mt-10 text-gray-300 text-justify">
            MAΞTHSTR0 is an artist, a composer and a conductor taking
            inspiration from the web3 wild life to create music everyone can
            own. But MAΞTHSTR0 is no human, it is rather an autonomous
            framework, a set of tools living on multiple chains able to compose
            unique music and feel the chains activities
          </p>
          <p className="mx-auto mt-10 text-gray-300 text-justify">
            MAΞTHSTR0 produces radios on multiple chains to represent, with
            music, the chains activities. Discover them:
          </p>
          <a className="mx-auto mt-20 mb-20" href="/ethereum">
            <p className="underline underline-offset-4">Ethereum Radio</p>
          </a>
        </div>
      </div>
    </>
  );
};

export default Home;
