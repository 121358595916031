import { Goerli, Mainnet, useEtherBalance, useEthers } from '@usedapp/core';
import { useEffect } from 'react';
import Modal from './Modal';

const Connect = () => {
  const { account, chainId, activateBrowserWallet, switchNetwork } =
    useEthers();
  const etherBalance = useEtherBalance(account);

  const handleConnect = () => {
    activateBrowserWallet();
  };

  useEffect(() => {
    if (
      process.env. !== 'true' &&
      account &&
      chainId !== Mainnet.chainId
    ) {
      switchNetwork(Mainnet.chainId);
    }
    if (
      process.env. === 'true' &&
      account &&
      chainId !== Goerli.chainId
    ) {
      switchNetwork(Goerli.chainId);
    }
  }, [account, chainId, switchNetwork]);

  return (
    <div>
      {account === undefined ? (
        <button
          className="rounded text-purple-500 hover:cursor-pointer"
          onClick={handleConnect}
        >
          Connect Wallet
        </button>
      ) : (
        <div className="flex justify-between min-w-[300px]">
          <p>
            {account.slice(0, 6)}...{account.slice(-4)}
          </p>
          <p>|</p>
          <p>
            {Mainnet.chainId === chainId
              ? 'Mainnet'
              : Goerli.chainId === chainId
              ? 'Goerli'
              : 'Invalid Network'}
          </p>
          <p>|</p>
          <p>
            {(parseInt(etherBalance?.toString() || '0') / 10 ** 18).toFixed(2)}{' '}
            ETH
          </p>
        </div>
      )}
      {account !== undefined &&
        ((process.env. !== 'true' &&
          chainId !== Mainnet.chainId) ||
          (process.env. === 'true' &&
            chainId !== Goerli.chainId)) && (
          <Modal
            children={
              <p>
                Changing network to{' '}
                {process.env. === 'true'
                  ? 'goerli'
                  : 'mainnet'}
                ...
              </p>
            }
            onClose={() => {}}
            noClose
          />
        )}
    </div>
  );
};

export default Connect;
